const teams = [
    {
        title: 'Our Content Marketing',
        team: [
            {
                img: require("../images/writers/1.jpeg").default,
                name: 'David'
            },
            {
                img: require("../images/writers/2.jpeg").default,
                name: 'Rae'
            },
            {
                img: require("../images/writers/3.jpeg").default,
                name: 'John'
            },
            {
                img: require("../images/writers/4.jpeg").default,
                name: 'Adelle'
            },
            {
                img: require("../images/writers/5.jpeg").default,
                name: 'Faris'
            },
            {
                img: require("../images/writers/6.jpeg").default,
                name: 'Samir'
            },
            {
                img: require("../images/writers/7.jpeg").default,
                name: 'Justine'
            },
            {
                img: require("../images/writers/8.jpeg").default,
                name: 'Rianna'
            },
            {
                img: require("../images/writers/9.jpeg").default,
                name: 'Micheal'
            },
            {
                img: require("../images/writers/13.jpeg").default,
                name: 'Elen'
            }
        ]
    },
    {
        title: 'Our SMM',
        team: [
            {
                img: require("../images/writers/w1.webp").default,
                name: 'Alvin'
            },
            {
                img: require("../images/writers/w2.webp").default,
                name: 'Ashlyn'
            },
            {
                img: require("../images/writers/w3.webp").default,
                name: 'Lynden'
            },
            {
                img: require("../images/writers/w4.webp").default,
                name: 'Anastasia'
            },
            {
                img: require("../images/writers/w5.webp").default,
                name: 'Vivek'
            },
            {
                img: require("../images/writers/w6.webp").default,
                name: 'Zion'
            },
            {
                img: require("../images/writers/w7.webp").default,
                name: 'Angela'
            },
            {
                img: require("../images/writers/w8.webp").default,
                name: 'Maegan'
            },
            {
                img: require("../images/writers/w9.webp").default,
                name: 'Eleanor'
            },
            {
                img: require("../images/writers/w10.webp").default,
                name: 'Olly'
            }
        ]
    },
    {
        title: 'Our SEO',
        team: [
            {
                img: require("../images/writers/12.jpeg").default,
                name: 'Aiesha'
            },
            {
                img: require("../images/writers/w12.webp").default,
                name: 'Kacper'
            },
            {
                img: require("../images/writers/w13.webp").default,
                name: 'Bernard'
            },
            {
                img: require("../images/writers/w14.webp").default,
                name: 'Bianca'
            },
            {
                img: require("../images/writers/w15.webp").default,
                name: 'John'
            },
            {
                img: require("../images/writers/w16.webp").default,
                name: 'Darryl'
            },
            {
                img: require("../images/writers/w17.webp").default,
                name: 'Bill'
            },
            {
                img: require("../images/writers/w18.webp").default,
                name: 'Hal'
            },
            {
                img: require("../images/writers/w19.webp").default,
                name: 'Samir'
            },
            {
                img: require("../images/writers/10.jpeg").default,
                name: 'Tomi'
            }
        ]
    },
    {
        title: 'Our E-mail Marketing',
        team: [
            {
                img: require("../images/writers/1.jpg").default,
                name: 'Danyal'
            },
            {
                img: require("../images/writers/16.jpeg").default,
                name: 'Cora'
            },
            {
                img: require("../images/writers/21.jpeg").default,
                name: 'Mathew'
            },
            {
                img: require("../images/writers/17.jpeg").default,
                name: 'Jasmin'
            },
            {
                img: require("../images/writers/2.jpg").default,
                name: 'Marek'
            },
            {
                img: require("../images/writers/19.jpeg").default,
                name: 'Brendan'
            },
            {
                img: require("../images/writers/20.jpeg").default,
                name: 'Joe'
            },
            {
                img: require("../images/writers/22.jpeg").default,
                name: 'Simeon'
            },
            {
                img: require("../images/writers/23.jpeg").default,
                name: 'Jett'
            },
            {
                img: require("../images/writers/3.jpg").default,
                name: 'Rima'
            }
        ]
    }
]

export default teams