import React from 'react';
import Layout from '../layouts/layout'
import teams from '../content/teams'
function Team() {
    return (
        <div>
            <Layout headerClass="bg-black h-[122px] relative">
                <div className='flex justify-center'>
                    <div className='container flex flex-col py-20'>
                        <h2 className='text-primary md:text-6xl text-4xl mb-5 font-[600]'>We Create Copies that Matter</h2>
                        <p className='md:max-w-p[570px] text-xl text-[rgba(47,47,47,.6)] mb-10'>
                            You need copy that sells. You need research and reporting that wins. We deliver relevant copy that speaks to your audience and helps you and your business stand out.
                        </p>
                        <div className='flex flex-col w-full'>
                            {teams.map((item, key) => {
                                return(
                                    <div key={key} className="team-list max-w-[980px] mb-20">
                                        <div className='team-title py-5'>
                                            <h3 className='text-[#181818] text-4xl font-[500]'>{item.title}</h3>
                                        </div>
                                        <div className='team-wrapper flex flex-wrap items-center'>
                                            {item.team.map((img, index) => {
                                                return(
                                                    <div className='team-img mr-5 mb-5'>
                                                        <div key={index} className="w-44 h-56">
                                                            <img loading="lazy" src={img.img} alt={img.name} className="w-full h-full object-cover rounded-br-[40px] bg-black-700 shadow-lg shadow-black-700/70"/>
                                                        </div>
                                                        <p className='text-xl mt-2'>{img.name}</p>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </Layout>
        </div>
    );
}

export default Team;